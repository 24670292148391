import React, { createContext, useContext, useState, useCallback } from 'react'
import { Modal, Backdrop, Fade, Box, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null)
  const [open, setOpen] = useState(false)

  const openModal = useCallback((Component, props = {}) => {
    setModalContent({ Component, props })
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
    setTimeout(() => setModalContent(null), 300) // Delay unmounting for smooth exit animation
  }, [])

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <CustomModal open={open} closeModal={closeModal} content={modalContent} />
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)

// Custom MUI Modal Component
const CustomModal = ({ open, closeModal, content }) => {
  if (!content) return null

  const { Component, props } = content

  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '750px',
            maxWidth: '80%',
            backgroundColor: 'white',
            boxShadow: 24,
            padding: '8px',
          }}
        >
          <IconButton
            onClick={closeModal}
            style={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Component {...props} />
        </Box>
      </Fade>
    </Modal>
  )
}

export default ModalContext
