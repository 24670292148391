import './src/styles/index.css'
import './src/styles/utilities.css'

export { wrapRootElement } from './src/utils/wrapRootElement'

// Save a backup of the original global CSS style node
let savedGlobalStyle = null

export const onInitialClientRender = () => {
  const original = document.getElementById('gatsby-global-css')

  if (original) {
    savedGlobalStyle = original.cloneNode(true)
    console.log('[gatsby] Saved initial global CSS.')
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(r => r.unregister())
    })
  }
}

export const onRouteUpdate = () => {
  const globalStyle = document.getElementById('gatsby-global-css')

  if (!globalStyle && savedGlobalStyle) {
    console.warn('[gatsby] Global CSS disappeared. Re-inserting saved copy.')
    document.head.appendChild(savedGlobalStyle.cloneNode(true))
  } else {
    console.log('[gatsby] Global CSS still intact.')
  }
}
