import React from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'
import { BreakpointProvider } from './breakpoints'
import { NavigationProvider } from './navigation'
import { ModalProvider } from '../context/ModalContext.js' // ✅ Import ModalProvider

export const wrapRootElement = ({ element }) => {
  let queries = {}

  // Generate an object of Tailwind CSS screen sizes to media queries
  const config = resolveConfig(tailwindConfig)
  for (const screen in config.theme.screens) {
    if (config.theme.screens.hasOwnProperty(screen)) {
      const size = config.theme.screens[screen]
      queries[screen] = `(min-width: ${size})`
    }
  }

  return (
    <NavigationProvider>
      <BreakpointProvider queries={queries}>
        <ModalProvider>
          {' '}
          {/* ✅ Wrap your app with the global modal provider */}
          {element}
        </ModalProvider>
      </BreakpointProvider>
    </NavigationProvider>
  )
}
